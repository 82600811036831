import React from "react";
import { Link } from "gatsby";
import {
  breadcrumb_wrapper,
  separator,
  current_location,
} from "../styles/modules/breadcrumb.module.css";

function BreadCrumbs({ breadCrumbsList, full = null }) {
  return (
    <nav className={breadcrumb_wrapper} full={full} aria-label="breadcrumbs">
      <ol>
        {breadCrumbsList.map((item, i) =>
          i === breadCrumbsList.length - 1 ? (
            <li key={i} className={current_location} aria-current="page">
              {item.name}
            </li>
          ) : (
            <li key={item.slug}>
              {/* <Link to={item.slug}>
                {`${item.name}`} {`         `}
              </Link> */}
              <a href={item.slug}>
                {`${item.name}`} {`         `}
              </a>
              <span role="separator" className={separator}>
                ►
              </span>
            </li>
          )
        )}
      </ol>
    </nav>
  );
}

export default BreadCrumbs;
