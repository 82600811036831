import { Link } from "gatsby";
import React from "react";
import { grid_wrapper, color_link } from "./styles.module.css";

function ColorsBox({ links, locale = "es" }) {
  const baseSlug =
    locale === "es"
      ? `/colores-html`
      : locale === "en"
      ? `/en/html-colors`
      : locale === "pt"
      ? `/pt/cores-html`
      : locale === "fr"
      ? `/fr/couleurs-html`
      : "";

  return (
    <div className={grid_wrapper}>
      {links.map(link => (
        <article>
          <a className={color_link} href={`${baseSlug}${link.slug}`}>
            <div style={{ backgroundColor: link.colorHex }}></div>
            <h3>
              {locale === "es"
                ? `Códigos color: `
                : locale === "en"
                ? `Color codes:`
                : locale === "pt"
                ? `Códigos de cores: `
                : locale === "fr"
                ? `Codes de couleurs: `
                : ""}{" "}
              {link.colorName}
            </h3>
          </a>
        </article>
      ))}
    </div>
  );
}

export default ColorsBox;
